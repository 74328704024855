@tailwind base;
/* Write your own custom base styles here */

/* reset: https://www.joshwcomeau.com/css/custom-css-reset/ */
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  /* font-family: 'LatoLatin', sans-serif; */
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 16px;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
a,
a:hover {
  color: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

@layer base {
  h1 {
    @apply text-6xl;
  }
  h2 {
    @apply text-5xl;
  }
  h3 {
    @apply text-4xl;
  }
  h4 {
    @apply text-3xl;
  }
  h5 {
    @apply text-2xl;
  }
  h6 {
    @apply text-xl;
  }
  a {
    @apply text-blue-600 hover:text-blue-500;
  }
}

input:disabled {
  background-color: #e6e6e6;
}
input:focus,
button:focus {
  outline: none !important;
}
