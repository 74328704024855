@tailwind components;
/* Write you own custom component styles here */

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../../public/assets/fonts/Lato/lato-v23-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../../public/assets/fonts/Lato/lato-v23-latin-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../public/assets/fonts/Lato/lato-v23-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../../public/assets/fonts/Lato/lato-v23-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../../public/assets/fonts/Lato/lato-v23-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../../public/assets/fonts/Lato/lato-v23-latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('../../public/assets/fonts/Lato/lato-v23-latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../../public/assets/fonts/Lato/lato-v23-latin-900.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Babyshine';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../public/assets/fonts/Babyshine.otf') format('opentype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.font-script {
  font-family: 'babyshine', cursive;
}

hr {
  @apply border-gray-100 my-6;
}

/**
 * ----------------------------------------
 * Scroll To Top
 * ----------------------------------------
 */
.scroll-top {
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
  z-index: 999;
}
.scroll-top-show {
  opacity: 1;
  transform: scale(1);
}

/**
 * ----------------------------------------
 * react-carousel-responsive overrides
 * ----------------------------------------
 */
.react-carousel-responsive__root {
  z-index: 1 !important;
}
.react-carousel-responsive__control:hover {
  background-color: var(--color-brand) !important;
}

.active {
  @apply rounded bg-brand-500 text-white;
}
.active:hover {
  @apply text-brand-100 !important;
}
